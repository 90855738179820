<template>
  <div class="login">
    <div class="login-progress">
      <van-image width="130" height="130" fit="cover" :src="require('@/assets/logo.jpg')" />
      <van-loading color="#40c057" />
      <span>{{ $t("logging in...") }}</span>
      <br>
    </div>
    <span class="version">v{{ version }}</span>
  </div>
</template>

<script>
import { Loading, Image as VanImage, Toast } from "vant";
import { PageRouter } from "@/utils/pages";

import storage from "@/utils/storage";
import { STORAGE_KEYS, LINE } from "@/utils/constants";
import { refreshAccessToken, lineLogin, fetchUserInfo } from "@/service/api";
import { lineWebLogin } from "@/service";
import { setAccessToken, getRefreshToken, setRedirectParams, getRedirectParams, clearRedirectParams } from "@/utils/token";
import { parseUrl, base26To10 } from "@/utils/utils";
import { toAuthorizationLogin } from "@/utils/pages";

export default {
  components: {
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
  },

  data() {
    return {
      version: process.env.VUE_APP_VERSION
    };
  },
  mounted() {
    const { code } = this.$route.query;
    //line login
    if (code)
      this.lineWebLogin(code);
    else
      this.checkIfLogin()
  },
  methods: {
    checkIfLogin() {
      let access_token = getRefreshToken();

      //如果已登录则去刷新token,否则跳转到登录页面
      if (access_token)
        this.refreshAccessToken(access_token);
      else {
        const queryParams = this.$route.query

        //缓存跳转参数
        if (queryParams)
          setRedirectParams(this.$route.query)
        toAuthorizationLogin();
      }
    },
    toTargetPage() {
      const lsQueryParams = getRedirectParams();

      //清除缓存的跳转参数
      clearRedirectParams()
      const queryMap = { ...this.$route.query, ...lsQueryParams };

      const { redirect } = queryMap;
      const queryParams = { ...queryMap };
      //去掉url中的跳转、line code、state参数
      delete queryParams.redirect;
      delete queryParams.code;
      delete queryParams.state;

      let target = redirect ? redirect : "home";
      switch (target) {
        case PageRouter.cargo_category:
          this.toWebPage("cargo-category");
          break;
        case PageRouter.customer_services:
          this.toWebPage("customer-services");
          break;
        case PageRouter.tukship_redpacket:
          {
            const { rp } = queryParams;
            const redPacketId = base26To10(rp);
            this.$router.replace({
              name: "redpacket",
              query: {
                redPacketId: redPacketId,
              },
            });
          }
          break;
        case PageRouter.cargo_register:
          this.$router.replace({
            name: "cargoRegister",
          });
          break;
        case PageRouter.cargo_detail:
          this.$router.replace({
            name: "cargoDetail",
            query: queryParams,
          });
          break;
        case PageRouter.order_details:
          this.$router.replace({
            name: "orderDetails",
            query: queryParams,
          });
          break;
        default:
          this.$router.replace({
            name: "Home",
            query: queryParams,
          });
          break;
      }
    },
    toWebPage(type) {
      this.$router.replace({
        name: "Home",
        query: {
          redirect: "help",
        },
        params: {
          webType: type,
        },
      });
    },
    async refreshAccessToken(access_token) {
      const res = await refreshAccessToken(access_token);

      if (res && res.value) {
        setAccessToken(res);
        this.fetchUserInfo();
      } else
        toAuthorizationLogin()
    },
    async lineWebLogin(code) {
      const payload = {
        createShopifyCustomer: false,
        loginChannel: "line_web_app",
        code,
        redirectUri: LINE.LOGIN_CALLBACK
      }
      const res = await lineWebLogin(payload);

      if (res && res.value) {
        setAccessToken(res);
        this.fetchUserInfo();
      } else Toast.fail("login failed");
    },
    async fetchUserInfo() {
      const res = await fetchUserInfo();

      if (res && res.success) {
        storage.setObj(STORAGE_KEYS.userInfo, res.data);
        this.userInfo = res.data;
        this.toTargetPage();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
}

.login-progress {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.allow {
  flex: 1;
  padding: 12px 0px;
  background-color: #40c057;
  text-align: center;
  color: white;
  border-radius: 5px;
}

.cancel {
  flex: 1;
  background-color: #ededed;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;
  padding: 10px 0px;
}

.version {
  position: fixed;
  bottom: 25px;
  left: 0px;
  right: 0px;
  font-size: 0.8rem;
  display: block;
  text-align: center;
}
</style>