import {
    LINE,
} from "@/utils/constants";

export const PageRouter = {
    cargo_category: "cargo-category",
    customer_services: "customer-services",
    tukship_redpacket: 'redpacket',
    cargo_register: 'cargo-register',
    cargo_detail: 'cargoDetail',
    order_details: 'orderDetails',
}

export function openWebPage(url) {
    window.open(url, "_self");
}

export function openWebPageBlank(url) {
    window.open(url, "_blank");
}

export function toLineLoginPage() {
    const redirectUrl = `${LINE.LOGIN_CALLBACK}`;
    const state = `tukship${Date.now()}`;
    const lineOAuthUtl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE.CLIENT_ID}&redirect_uri=${redirectUrl}&state=${state}&scope=profile%20openid`;
    openWebPage(lineOAuthUtl)
}

export function toAuthorizationLogin() {
    toLineLoginPage();
}